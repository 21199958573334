var formCallbacks = {
	'_ctaDisable': function(formName) {
		var cta = $('[data-form='+ formName +'][data-form-block=cta]');
		cta.data('originalContent', cta.html());
		cta.html('Odesílám <i class="fa fa-spinner fa-spin"></i>');
		cta.prop('disabled', true);
	},
	'_ctaEnable': function(formName) {
		var cta = $('[data-form='+ formName +'][data-form-block=cta]');
		cta.html(cta.data('originalContent'));
		cta.prop('disabled', false);
	},
	'_formBlockClass': function(formName, css) {
		$('[data-form='+ formName +'][data-form-block=replaceable-content]').toggleClass(css);
		$('[data-form='+ formName +'][data-form-block=content]').toggleClass(css);
	},
	'_formBlockContent': function(formName, content) {
		$('[data-form='+ formName +'][data-form-block=replaceable-content]').html(content);
	},
	'_formBlockError': function(formName, res) {
		var form = $('[data-form='+ formName +']');
		for (var fieldName in res.responseJSON) {
			form.find('form[' + fieldName + ']').addClass('is-invalid');
		}
		// hack for specific form
		if (formName === 'contactFooterForm') {
			form.find('[name=form\\[contact\\]]').addClass('is-invalid');
		}
		form.find('.form-error-message').removeClass('d-none');
	}
};