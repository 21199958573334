function scrollToAnchor() {
	if (window.location.hash.length > 0) {
		var id = window.location.hash.replace('#', '');
		var el = $('[data-id=' + id + ']');
		if (el.length === 1) {
			scrollToElement(el);
		}
	}
}

function scrollToElement(el) {
	$.scrollTo(el, 500, {
		'offset': ($('header').height() + 50) * -1
	});
}

$(document).ready(function(e){

	// scrolling to anchor
	$(window).bind('hashchange', function(e){
		e.preventDefault();
		scrollToAnchor();
	});
	scrollToAnchor();

	// carousel
	$('[data-carousel]').each(function(){
		var el = $(this);
		var id = el.data('carousel');
		el.slick({
			'arrows': false,
			'autoplay': true,
			'dots': true,
			'appendDots': $('[data-carousel-dots="' + id + '"]')
		});
		$(document).find('[data-carousel-prev="' + id + '"] button').click(function(e){
			e.preventDefault();
			el.slick('slickPrev');
		});
		$(document).find('[data-carousel-next="' + id + '"] button').click(function(e){
			e.preventDefault();
			el.slick('slickNext');
		});

	});

	// jobs
	$('[data-job-id]').each(function(){
		var el = $(this);
		el.find('.box-menu-item').click(function(e){
			$('[data-job-id]').removeClass('box-menu-open').addClass('box-menu-closed');
			el.toggleClass('box-menu-closed box-menu-open');
		});
		el.find('.box-menu-cta').click(function(e){
			e.preventDefault();
			var formEl = $('[data-id=job-form]');
			$('#form_job').val(el.data('jobId'));
			scrollToElement(formEl);
		});
	});

	// gallery
	lightbox.option({
		'albumLabel': 'Obrázek %1 / %2'
	});

	// forms
	$('[data-form-ajax]').submit(function(e){

		e.preventDefault();

		var el = $(this);
		var formName = el.data('form');

		formCallbacks._formBlockClass(formName, 'form-loading');
		formCallbacks._ctaDisable(formName);

		$.post(el.data('formAjax'), el.serialize())
			.done(function(res){
				formCallbacks._formBlockClass(formName, 'form-loading form-ok');
				formCallbacks._formBlockContent(formName, res);
				el.addClass('form-ok');
			})
			.fail(function(res){
				formCallbacks._formBlockClass(formName, 'form-loading form-error');
				formCallbacks._formBlockError(formName, res);
			})
			.always(function(res){
				formCallbacks._ctaEnable(formName);
				if (formCallbacks[formName]) {
					formCallbacks[formName](res);
				}
			});
	});


});